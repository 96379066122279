<template>
  <v-card class="overflow-y-auto" max-height="250" min-height="250">
    <v-tabs v-if="isCafereoUser" v-model="tab" class="mt-2 mb-2">
      <v-tab><v-icon>mdi-newspaper</v-icon>重要なお知らせ</v-tab>
      <v-tab><v-icon>mdi-web</v-icon>システム情報</v-tab>
    </v-tabs>
    <v-card-title v-if="!isCafereoUser"><v-icon>mdi-newspaper</v-icon>重要なお知らせ</v-card-title>
    <v-tabs-items v-model="tab" id="tab-items">
      <v-tab-item>
        <v-data-table
          :headers="headers"
          :items="seriousNotifications"
          hide-default-footer
          disable-sort
          fixed-header
          height="185px"
          dense
        ></v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-data-table
          :headers="systemHeaders"
          :items="systemInfos"
          hide-default-footer
          fixed-header
          height="185px"
          dense
        ></v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
export default {
  name: "NewsPaper",
  props: ["seriousNotifications", "systemInfos"],
  data: () => ({
    headers: [
      { text: "日付", value: "infomationDate", width: "150" },
      { text: "内容", value: "contents" },
    ],
    systemHeaders: [
      { text: "リソース", value: "procName", width: "300" },
      { text: "実行者", value: "userName", width: "150" },
      { text: "ステータス", value: "statusName", width: "150" },
      { text: "開始", value: "startDate", width: "150" },
      { text: "終了", value: "endDate", width: "150" },
    ],
    tab: null,
  }),
};
</script>
