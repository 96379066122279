<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="8" lg="9">
        <news-paper :seriousNotifications="seriousNotifications"></news-paper>
      </v-col>
      <v-col cols="12" md="4" lg="3">
        <sales-staff :userInfo="userInfo"></sales-staff>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-card :to="{ name: 'PurchaseSearch1-5' }" max-width="344" max-height="200">
      <v-card-title>受注状況</v-card-title>
      <v-divider></v-divider>
      <v-card-text
        >納品日を確定していない受注が<span style="color: red">{{ unResponsePurchase }}件</span>あります</v-card-text
      >
      <v-card-actions>
        <v-btn text color="teal accent-4" exact :to="{ name: 'PurchaseSearch1-5' }"
          ><v-icon>mdi-calendar</v-icon>納品日を回答する</v-btn
        >
      </v-card-actions></v-card
    >
  </v-container>
</template>

<script>
import NewsPaper from "./common/NewsPaper.vue";
import SalesStaff from "./common/SalesStaff.vue";
import { statuses as ApiStatus } from "../../libs/api-client";

export default {
  name: "MakerDashboard1-5",
  props: ["userInfo", "banners", "seriousNotifications"],
  components: {
    NewsPaper,
    SalesStaff,
  },
  data() {
    return {
      unResponsePurchase: null,
      resultOver: false,
    };
  },
  beforeMount() {
    this.init();
  },
  methods: {
    init() {
      this.countUnResponsePurchase();
    },
    async countUnResponsePurchase() {
      try {
        this.loadingOn();
        const response = await this.$store.dispatch("purchase/search", {
          searchConditions: { unfixedDeliveryTimeFlg: true },
        });
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        const statedPurchases = response.data.contents.statedPurchases || {};
        this.unResponsePurchase =
          statedPurchases.unapproved.length + statedPurchases.approved.length + statedPurchases.answered.length;

        if (result.over) {
          this.resultOver = true;
        }
      } catch (error) {
        console.error("PurchaseSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
  },
};
</script>
