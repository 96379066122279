<template>
  <v-app>
    <v-app-bar color="#BAD3FF" app clipped-left>
      <v-toolbar-title>
        <router-link :to="{ name: 'Dashboard1-5' }">
          <span style="image-rendering: -webkit-optimize-contrast"
            ><v-img :src="require('../../assets/delphoi.png')" height="50" width="190" position="left"></v-img
          ></span>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        absolute
        bottom
        v-model="notice_menu"
        min-width="550"
        max-width="550"
        :close-on-click="false"
        :close-on-content-click="false"
      >
      </v-menu>
      <v-menu offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip left :disabled="$vuetify.breakpoint.mdAndUp">
            <template v-slot:activator="{ on: tooltip }">
              <v-chip label color="secondary" v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                <v-avatar :left="$vuetify.breakpoint.mdAndUp">
                  <v-icon>mdi-account-circle</v-icon>
                </v-avatar>
                <template v-if="$vuetify.breakpoint.mdAndUp">
                  {{ username }}
                </template>
              </v-chip>
            </template>
            {{ username }}
          </v-tooltip>
        </template>
        <v-list dense>
          <v-list-item @click="password_dialog = true">
            <v-list-item-icon><v-icon>mdi-key</v-icon></v-list-item-icon>
            <v-list-item-title>パスワード変更</v-list-item-title>
          </v-list-item>
          <v-list-item @click="config_dialog = true">
            <v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
            <v-list-item-title>個人設定</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="onLogoutClick">
            <v-list-item-icon><v-icon>mdi-logout-variant</v-icon></v-list-item-icon>
            <v-list-item-title>ログアウト</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog v-model="profile_dialog" max-width="600" scrollable>
        <user-profile
          :user="loggedInUser"
          :show="profile_dialog"
          @close="profile_dialog = false"
          @password="password_dialog = true"
          v-if="profile_dialog"
        ></user-profile>
      </v-dialog>
      <v-dialog v-model="password_dialog" max-width="450">
        <password-change
          @submit="onPasswordCommit"
          @cancel="password_dialog = false"
          v-if="password_dialog"
        ></password-change>
      </v-dialog>
      <v-dialog
        v-model="notice_rules_dialog.show"
        :max-width="notice_rules_dialog.expand ? '100%' : '600px'"
        scrollable
      >
        <notification-setting
          @close="notice_rules_dialog.show = false"
          @submit="notice_rules_dialog.show = true"
          @expand="onNoticeRulesExpand"
          v-if="notice_rules_dialog.show"
        ></notification-setting>
      </v-dialog>
      <v-dialog v-model="config_dialog" max-width="450">
        <v-card v-if="config_dialog">
          <v-card-title class="text-h6"><v-icon>mdi-cog</v-icon>個人設定</v-card-title>
          <v-card-text>
            <v-form>
              <v-col cols="12">
                <v-select
                  :items="[20, 50, 100]"
                  label="１ページ当たりの表示件数"
                  v-model="config_model.default_page_size"
                  return-object
                  hide-details
                ></v-select>
              </v-col>
              <input style="display: none" />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary" @click="onSettingCancel()">キャンセル</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="onSettingCommit()">更新</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer color="white" app> Copyright &copy; CafeReoCo.,Ltd. All Rights Reserved. </v-footer>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
      <span class="ml-5" style="user-select: none">{{ loadingMessage }}</span>
    </v-overlay>
  </v-app>
</template>

<script>
import NoticeRule from "../../consts/NoticeRule";
import UserProfile from "../../components/user/UserProfile.vue";
import PasswordChange from "../../components/user/PasswordChange.vue";
import NotificationSetting from "../../components/user/NotificationSetting.vue";
import moment from "moment";

export default {
  name: "MainPage1-5",
  components: {
    UserProfile,
    PasswordChange,
    NotificationSetting,
  },
  data: () => ({
    drawer: null,
    notice_menu: false,
    notice_dialog: false,
    selected_notice: {},
    notice_active_tab: null,
    notise_page_size: 5,
    serious_notice_show_page: 1,
    general_notice_show_page: 1,
    profile_dialog: false,
    notice_rules_dialog: { show: false, expand: false },
    notice_rules: NoticeRule,
    enabled_notice_rules: [10101, 10102, 10103, 10201, 10302],
    password_dialog: false,
    config_dialog: false,
    config_model: {
      default_page_size: 20,
    },
    idName: "notification",
    generalSearchStr: null,
    seriousSearchStr: null,
  }),
  beforeMount() {
    this.config_model.default_page_size = this.globalPageSize;
    localStorage.removeItem(this.idName);
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
    loadingMessage() {
      return this.$store.getters["ui/loadingMessage"];
    },
    username() {
      return this.$store.getters["security/displayname"];
    },
    loggedInUser() {
      return this.$store.getters["security/loggedInUser"];
    },

  },
  methods: {
    onNoticeRuleCommit() {
      this.$dialog.notify.info("通知設定が更新されました", { timeout: 2300 });
      this.notice_rules_dialog.show = false;
    },
    onPasswordCommit() {
      this.password_dialog = false;
    },
    onSettingCommit() {
      this.$store.commit("ui/pagesize", this.config_model.default_page_size);
      this.$dialog.notify.info("個人設定が変更されました", { timeout: 2300 });
      this.config_dialog = false;
    },
    async onLogoutClick() {
      const ok = await this.$dialog.confirm({
        title: "",
        text: `ログアウトしますか？`,
      });
      if (ok) {
        this.$store.commit("security/logout");
        this.$router.push({ name: "Login" });
      }
    },
    formatDate(strDate) {
      let ret = "";
      if (strDate) {
        let momentdate = moment(strDate);
        ret = momentdate.format("YYYY/MM/DD") + "\n" + momentdate.format("HH:mm");
      }
      return ret;
    },
    onNoticeRulesExpand(value) {
      this.notice_rules_dialog.expand = value;
    },
    onNoticeMenuClose() {
      this.readedNotice();
      this.notice_menu = false;
    },
    onSettingCancel() {
      this.config_model.default_page_size = this.globalPageSize;
      this.config_dialog = false;
    },
    async onReadedAll(notifications) {
      console.log("onReadedAll notifications");
      console.log(notifications);
      let cnt = 0;
      for (let e of notifications) {
        if (!e.readedFlag) {
          this.$store.commit("notice/calcNum", -1);
          this.$store.commit("notice/mark", e.notificationId);
          //既読情報一時保存
          this.saveNoticeRead(e.notificationId);
          cnt++;
          if (cnt % 500 == 0) {
            await this.readedNotice();
          }
        }
      }
    },
  },
};
</script>
